import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { ChonkyActions, FileHelper, FullFileBrowser, defineFileAction} from 'chonky'
import SV2IconComponent from './ChonkyIconFA'
import './styles.scss'
import SandBoxContext from '../../contexts/SandBoxContext'
import { FlowOverlayPrompt } from '../flow-ui/flow-blocks'
import {StorageUploader, RenamePanel, CreateFolderPanel, DeletePanel, getFilePath, MoveLoadingPanel, downloadFile} from './storage-components'

const FsMap = {
  rootFolderId: 'My Drive/',
  fileMap: {
    'My Drive/': {
      id: 'My Drive/',
      name: 'My Drive',
      isDir: true,
      childrenIds: [],
      childrenCount: 0
    }
  }
}

const useCustomFileMap = () => {
  const {sandboxState, setFileMap} = useContext(SandBoxContext)
  const {fileMap, rootFolderId} = sandboxState
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId)
  const currentFolderIdRef = useRef(currentFolderId)
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId
  }, [currentFolderId])

  const deleteFiles = useCallback((files) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap }

      files.forEach((file) => {
        delete newFileMap[file.id]

        if (file.parentId) {
          const parent = newFileMap[file.parentId]
          const newChildrenIds = parent.childrenIds.filter(
            (id) => id !== file.id
          )
          newFileMap[file.parentId] = {
            ...parent,
            childrenIds: newChildrenIds,
            childrenCount: newChildrenIds.length,
          }
        }
      })
      return newFileMap
    })
  }, [])

  const moveFiles = useCallback((files, source, destination) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap }
      const moveFileIds = new Set(files.map(f => f.id))

      // Update source folder
      const newSourceChildrenIds = source.childrenIds.filter(
        (id) => !moveFileIds.has(id)
      )
      newFileMap[source.id] = {
        ...source,
        childrenIds: newSourceChildrenIds,
        childrenCount: newSourceChildrenIds.length
      }

      // Update destination folder
      const newDestinationChildrenIds = [
        ...destination.childrenIds,
        ...files.map(f => f.id)
      ]
      newFileMap[destination.id] = {
        ...destination,
        childrenIds: newDestinationChildrenIds,
        childrenCount: newDestinationChildrenIds.length
      }

      // Update moved files
      files.forEach((file) => {
        newFileMap[file.id] = {
          ...file,
          parentId: destination.id
        }
      })

      return newFileMap
    })
  }, [])

  const idCounter = useRef(0)
  const createFolder = useCallback((folderName) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap }
      const newFolderId = `new-folder-${idCounter.current++}`
      newFileMap[newFolderId] = {
        id: newFolderId,
        name: folderName,
        isDir: true,
        modDate: new Date(),
        parentId: currentFolderIdRef.current,
        childrenIds: [],
        childrenCount: 0
      }

      const parent = newFileMap[currentFolderIdRef.current]
      newFileMap[currentFolderIdRef.current] = {
        ...parent,
        childrenIds: [...parent.childrenIds, newFolderId]
      }

      return newFileMap
    })
  }, [])

  const uploadFiles = useCallback((data, setOverlay = ()=>{}) => {
    let folderChain = getFolderChain(fileMap, currentFolderId)
    setOverlay(
      <GeneralPanel 
        folderChain={folderChain}
        onCompletion={ (filePath, fileName) => {
          setFileMap((currentFileMap) => {
            const newFileMap = { ...currentFileMap }
            const newFileID = filePath
            newFileMap[newFileID] = {
              id: newFileID,
              name: fileName,
              isDir: false,
              modDate: new Date(),
              parentId: currentFolderIdRef.current,
              childrenIds: [],
              childrenCount: 0
            }

            const parent = newFileMap[currentFolderIdRef.current]
            newFileMap[currentFolderIdRef.current] = {
              ...parent,
              childrenIds: [...parent.childrenIds, newFileID]
            }

            return newFileMap
          })
          setOverlay(null)
        } }
      />
    )
  })

  const renameElement = useCallback((file, newName) => {
    // Update the fileMap with the new name
    setFileMap((currentFileMap) => {
      const updatedFileMap = { ...currentFileMap }
      if (updatedFileMap[file.id]) {
        updatedFileMap[file.id] = {
          ...updatedFileMap[file.id],
          name: newName
        }
      }
      return updatedFileMap
    })
  }, [])

  return {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    uploadFiles,
    renameElement
  }
}

const _useCustomFileMap = ({initBaseFileMap, initRootFolderId}) => {
  const { baseFileMap, rootFolderId } = useMemo(() => {
    return {baseFileMap: initBaseFileMap, rootFolderId: initRootFolderId}
  }, [])

  const [fileMap, setFileMap] = useState(baseFileMap)
  const [currentFolderId, setCurrentFolderId] = useState(rootFolderId)

  const resetFileMap = useCallback(() => {
    setFileMap(baseFileMap)
    setCurrentFolderId(rootFolderId)
  }, [baseFileMap, rootFolderId])

  const currentFolderIdRef = useRef(currentFolderId)
  useEffect(() => {
    currentFolderIdRef.current = currentFolderId
  }, [currentFolderId])

  const deleteFiles = useCallback((files) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap }

      files.forEach((file) => {
        delete newFileMap[file.id];

        if (file.parentId) {
          const parent = newFileMap[file.parentId]
          const newChildrenIds = parent.childrenIds.filter(
            (id) => id !== file.id
          )
          newFileMap[file.parentId] = {
            ...parent,
            childrenIds: newChildrenIds,
            childrenCount: newChildrenIds.length,
          }
        }
      })

      return newFileMap;
    });
  }, [])

  const moveFiles = useCallback((files, source, destination) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap }
      const moveFileIds = new Set(files.map(f => f.id))

      // Update source folder
      const newSourceChildrenIds = source.childrenIds.filter(
        (id) => !moveFileIds.has(id)
      )
      newFileMap[source.id] = {
        ...source,
        childrenIds: newSourceChildrenIds,
        childrenCount: newSourceChildrenIds.length,
      }

      // Update destination folder
      const newDestinationChildrenIds = [
        ...destination.childrenIds,
        ...files.map(f => f.id),
      ]
      newFileMap[destination.id] = {
        ...destination,
        childrenIds: newDestinationChildrenIds,
        childrenCount: newDestinationChildrenIds.length,
      }

      // Update moved files
      files.forEach((file) => {
        newFileMap[file.id] = {
          ...file,
          parentId: destination.id,
        }
      })

      return newFileMap
    })
  }, [])

  const idCounter = useRef(0);
  const createFolder = useCallback((folderName) => {
    setFileMap((currentFileMap) => {
      const newFileMap = { ...currentFileMap };
      const newFolderId = `new-folder-${idCounter.current++}`;
      newFileMap[newFolderId] = {
        id: newFolderId,
        name: folderName,
        isDir: true,
        modDate: new Date(),
        parentId: currentFolderIdRef.current,
        childrenIds: [],
        childrenCount: 0,
      };

      const parent = newFileMap[currentFolderIdRef.current];
      newFileMap[currentFolderIdRef.current] = {
        ...parent,
        childrenIds: [...parent.childrenIds, newFolderId],
      };

      return newFileMap;
    });
  }, [])

  const uploadFiles = useCallback((data, setOverlay = ()=>{}) => {
    let folderChain = getFolderChain(fileMap, currentFolderId)
    setOverlay(<GeneralPanel folderChain={folderChain}/>)
  })

  const renameElement = useCallback((file, newName) => {
    // Update the fileMap with the new name
    setFileMap((currentFileMap) => {
      const updatedFileMap = { ...currentFileMap }
      if (updatedFileMap[file.id]) {
        updatedFileMap[file.id] = {
          ...updatedFileMap[file.id],
          name: newName
        }
      }
      return updatedFileMap
    })
  }, [])

  return {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    resetFileMap,
    deleteFiles,
    moveFiles,
    createFolder,
    uploadFiles,
    renameElement
  }
}

const getFolderChain = (fileMap, currentFolderId) => {
  const currentFolder = fileMap[currentFolderId]
  const folderChain = [currentFolder]

  let parentId = currentFolder.parentId
  while (parentId) {
    const parentFile = fileMap[parentId]
    if (parentFile) {
      folderChain.unshift(parentFile)
      parentId = parentFile.parentId
    } else {
      break
    }
  }

  return folderChain
}

export const useFiles = (fileMap, currentFolderId) => {
  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId]
    const childrenIds = currentFolder.childrenIds
    return childrenIds.map(fileId => fileMap[fileId])
  }, [currentFolderId, fileMap])
}

export const useFolderChain = (fileMap, currentFolderId) => {
  return useMemo(() => {
    const currentFolder = fileMap[currentFolderId];
    const folderChain = [currentFolder];

    let parentId = currentFolder.parentId;
    while (parentId) {
      const parentFile = fileMap[parentId];
      if (parentFile) {
        folderChain.unshift(parentFile);
        parentId = parentFile.parentId;
      } else {
        break;
      }
    }

    return folderChain;
  }, [currentFolderId, fileMap]);
}

export const useFileActionHandler = (
  fileMap,
  setOverlay,
  currentFolderId,
  setCurrentFolderId,
  deleteFiles,
  moveFiles,
  createFolder,
  uploadFiles,
  renameElement,
) => {
  return useCallback((data) => {
    if (data.id === ChonkyActions.OpenFiles.id) {
      const { targetFile, files } = data.payload
      const fileToOpen = targetFile ?? files[0]
      if (fileToOpen && FileHelper.isDirectory(fileToOpen)) {
        setCurrentFolderId(fileToOpen.id)
        return
      }
    } else if (data.id === DeleteAction.id) {
      let file = data.state.selectedFilesForAction?.[0] ?? null
      if(file) {
        let folderChain = getFolderChain(fileMap, file.parentId)
        setOverlay(
          <DeletePanel
            file={file}
            folderChain={folderChain}
            onConfirm={val => {
              deleteFiles(data.state.selectedFilesForAction)
              setOverlay(null)
            }}
          />
        )
      }
    } else if (data.id === ChonkyActions.MoveFiles.id) {
      let files = data.payload.files ?? []
      let destination = data.payload.destination ?? null
      if(files?.length > 0 && destination) {
        let filePaths = files.map(f => getFilePath(fileMap, f))
        let destinationPath = getFilePath(fileMap, destination)
        setOverlay(
          <MoveLoadingPanel
            source={filePaths.join(' ')}
            target={destinationPath}
            onConfirm={() => {
              moveFiles(
                data.payload.files,
                data.payload.source,
                data.payload.destination
              )
              setOverlay(null)
            }}
          />
        )
      }
    } else if (data.id === ChonkyActions.CreateFolder.id) {
      let folderChain = getFolderChain(fileMap, currentFolderId)
      setOverlay(
        <CreateFolderPanel
          folderChain={folderChain}
          onConfirm={folderName => {
            createFolder(folderName)
            setOverlay(null)
          }}
        />
      )
    } else if (data.id === ChonkyActions.UploadFiles.id) {
      uploadFiles(data, setOverlay)
    } else if(data.id === DownloadAction.id) {
      let file = data.state.selectedFilesForAction?.[0] ?? null
      if(file) {
        let folderChain = getFolderChain(fileMap, file.parentId)
        downloadFile(file, folderChain)
      }
    } else if (data.id === RenameAction.id) {
      let file = data.state.selectedFilesForAction?.[0] ?? null
      if(file) {
        let folderChain = getFolderChain(fileMap, file.parentId)
        setOverlay(
          <RenamePanel
            file={file}
            folderChain={folderChain}
            onConfirm={newName => {
              renameElement(file, newName)
              setOverlay(null)
            }}
          />
        )
      }
    }
    // showActionNotification(data);
  }, [createFolder, deleteFiles, moveFiles, setCurrentFolderId, uploadFiles, renameElement])
}

const RenameAction = defineFileAction({
  id: 'rename',
  button: {
    name: 'Rename',
    toolbar: false,
    contextMenu: true,
    tooltip: 'Rename File/Folder',
    icon: 'custom-icon-rename'
  },
  fileFilter: (file, index, selections) => {
    return selections?.length === 1
  },
  requiresSelection: true
})

const DeleteAction = defineFileAction({
  id: 'delete',
  button: {
    name: 'Delete Item',
    toolbar: false,
    contextMenu: true,
    tooltip: 'Delete File/Folder',
    icon: 'custom-icon-delete'
  },
  fileFilter: (file, index, selections) => {
    return selections?.length === 1
  },
  requiresSelection: true
})

const DownloadAction = defineFileAction({
  id: 'download',
  button: {
    name: 'Download File',
    toolbar: false,
    contextMenu: true,
    tooltip: 'Download File',
    icon: 'custom-icon-download'
  },
  fileFilter: (file) => {
    return file?.isDir === false
  },
  requiresSelection: true
})

export const VFSBrowser = React.memo((props) => {
  const {setOverlay} = useContext(SandBoxContext)
  const {
    fileMap,
    currentFolderId,
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    uploadFiles,
    renameElement
  } = useCustomFileMap()
// } = useCustomFileMap({initBaseFileMap: props.initBaseFileMap, initRootFolderId: props.initRootFolderId})

  const files = useFiles(fileMap, currentFolderId)
  const folderChain = useFolderChain(fileMap, currentFolderId)
  const handleFileAction = useFileActionHandler(
    fileMap,
    setOverlay,
    currentFolderId,
    setCurrentFolderId,
    deleteFiles,
    moveFiles,
    createFolder,
    uploadFiles,
    renameElement
  )

  const fileActions = [
    ChonkyActions.OpenFiles,
    ChonkyActions.CreateFolder,
    DownloadAction,
    RenameAction,
    ChonkyActions.CopyFiles,
    DeleteAction,
    ChonkyActions.UploadFiles,
    ChonkyActions.MoveFiles,
    ChonkyActions.EnableListView
  ]

  const thumbnailGenerator = useCallback(
    (file) => file.thumbnailUrl ? `https://chonky.io${file.thumbnailUrl}` : null, []
  )

  return (
    <div className='file-explorer-modifier' style={{ height: 400 }}>
      <FullFileBrowser
        files={files}
        darkMode={true}
        iconComponent={SV2IconComponent}
        folderChain={folderChain}
        fileActions={fileActions}
        disableDefaultFileActions={true}
        defaultFileViewActionId={ChonkyActions.EnableListView.id}
        onFileAction={handleFileAction}
        clearSelectionOnOutsideClick={true}
        thumbnailGenerator={thumbnailGenerator}
        {...props}
      />
    </div>
  )
})

const GeneralPanel = ({ folderChain=[], onCompletion= () => {} }) => {
  const {setOverlay} = useContext(SandBoxContext)

  if(folderChain?.length <= 0) {
    return null
  }
  const folderPrefix = folderChain.map(f => f.name).join('/')
  
  return (
    <FlowOverlayPrompt
      title='Upload File'
      // promptMsg='Hello, This is the upload file prompt msg'
      promptMsg={<StorageUploader 
        directory={folderPrefix} 
        onCompletion={ (filePath, fileName) => {
          if(filePath && fileName) {
            console.log('Upload complete', filePath, fileName)
            onCompletion(filePath, fileName)
          } else {
            console.log('Failed to upload file')
          }
        } }
      />}
      titleIcon={<i className="fal fa-exclamation-triangle" style={{color: 'orange'}}></i>}
      proceedMsg={<><i className="fal fa-times" style={{color: 'crimson'}}></i>&nbsp;Cancel</>}
      singleButton={true}
      onProceed={() => { setOverlay(null) }}
    />
  )
}
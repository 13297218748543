import React, { Component, createContext } from 'react'
import Axios from '../utilities/axios'
import { useParams } from 'react-router-dom'

const CloudContext = createContext()

class CloudHandler extends Component {
  constructor(props) {
    super(props)
    this.state = {
			instanceID: null,
      deploymentID: null,
      instanceStatus: null,
      isCloudLoading: false,
      services: null,
      projectStatus: 'unknown',
      deploymentStatus: 'unknown',
      updatedDeploy: null,
      deploymentUri: ''
		}
  }

  _isMounted = false
  _intervalID = null

  _internalMapping = {
    'compute': 'main',
    'deploy': 'deploy'
  }

  fetchStatus = () => {
    let projectID = String(this.props.params.id)
    Axios.post(`/project/cloud-details`, {projectID}).then(response => {
      if(response.status === 200 && response.data) {
        if(this._isMounted) {
          this.setState({services: response.data})
          if(response.data?.projectStatus) {
            if(response.data.projectStatus === 'exit') {
              if(this._intervalID) {
                clearInterval(this._intervalID)
              }
            }
            this.setState({projectStatus: response.data?.projectStatus})
          }
        }
      }
    }).catch(err => {
      console.log('Error fetching project status')
    })
  }

  createInstance = async () => {
    if(this.state.projectStatus !== 'idle') return
    if(!this.state.isCloudLoading) {
      this.setState({isCloudLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID) {
        try {
          await Axios.get(`/instance/control/delete/${projectID}`)
        } catch(err) {
          console.log(err)
        }
        Axios.get(`/instance/control/new/${projectID}`).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              if(response.data.status === 'RUNNING') {
                this._intervalID = setInterval(() => {
                  this.fetchStatus()
                }, 5000)
                this.setState({
                  instanceID: response.data,
                  isCloudLoading: false,
                  projectStatus: ''
                })
              } else {
                console.log('Error starting instance')
              }
            }
          } else {
            if(this._isMounted) {
              this.setState({
                isCloudLoading: false
              })
            }
          }
        }).catch(err => {
          console.log('Axios createInstance error', err)
          if(this._isMounted) {
            this.setState({
              isCloudLoading: false
            })
          }
        })
      }
    }
  }

  deployService = async () => {
    if(!this.state.isCloudLoading) {
      this.setState({isCloudLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID && this.state.services.deploymentStatus === 'idle') {
        Axios.get(`deploy/service/create/${projectID}`).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              if(response.data.status === 'Deploying') {
                this.setState({
                  services: response.data?.services,
                  deploymentID: response.data.deployID,
                  isCloudLoading: false,
                  deploymentStatus: 'Deploying'
                })
              } else {
                this.setState({ services: response.data?.services, isCloudLoading: false})
                console.log('Error Deploying Function')
              }
            }
          } else {
            if(this._isMounted) {
              this.setState({
                isCloudLoading: false
              })
            }
          }
        }).catch(err => {
          console.log('Axios deployService error', err)
          if(this._isMounted) {
            this.setState({
              isCloudLoading: false
            })
          }
        })
      }
    }
  }

  updateDeployService = async () => {
    if(!this.state.isCloudLoading) {
      this.setState({isCloudLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID && this.state.services.deploymentStatus === 'deployed') {
        Axios.get(`deploy/service/update/${projectID}`).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              if(response.data.status === 'updated') {
                this.setState({ updatedDeploy: 'updated', isCloudLoading: false })
              } else {
                this.setState({ services: response.data?.services, isCloudLoading: false})
                console.log('Error Deploying Function')
              }
            }
          } else {
            if(this._isMounted) {
              this.setState({
                isCloudLoading: false
              })
            }
          }
        }).catch(err => {
          console.log('Axios deployService error', err)
          if(this._isMounted) {
            this.setState({
              isCloudLoading: false
            })
          }
        })
      }
    }
  }

  deleteDeployService = async () => {
    if(!this.state.isCloudLoading) {
      this.setState({isCloudLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID) {
        Axios.get(`deploy/service/delete/${projectID}`).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              if(response.data.deploymentStatus === 'idle') {
                let {services} = this.state || {}
                services['latestDeployment'] = null
                services['numOfDeployments'] = 0
                services['deploymentStatus'] = 'idle'
                this.setState({
                  services: services,
                  deploymentID: null,
                  isCloudLoading: false,
                  deploymentStatus: 'idle'
                })
              } else {
                this.setState({isCloudLoading: false})
                console.log('Error Deploying Function')
              }
            }
          } else {
            if(this._isMounted) {
              this.setState({
                isCloudLoading: false
              })
            }
          }
        }).catch(err => {
          console.log('Axios deployService error', err)
          if(this._isMounted) {
            this.setState({
              isCloudLoading: false
            })
          }
        })
      }
    }
  }

  getDeploymentEndpoint = async () => {
    if(!this.state.isCloudLoading) {
      this.setState({isCloudLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID && this.state.services.deploymentStatus === 'deployed') {
        Axios.get(`deploy/service/info/${projectID}`).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              if(response.data.uri) {
                this.setState({ deploymentUri: response.data.uri, isCloudLoading: false })
              } else {
                this.setState({isCloudLoading: false})
                console.log('Error Deploying Function')
              }
            }
          } else {
            if(this._isMounted) {
              this.setState({
                isCloudLoading: false
              })
            }
          }
        }).catch(err => {
          console.log('Axios deployService error', err)
          if(this._isMounted) {
            this.setState({
              isCloudLoading: false
            })
          }
        })
      }
    }
  }

  componentDidMount() {
    this._isMounted = true
    // this.fetchStatus()
  }
  
  render() {
    return(
      <CloudContext.Provider value={{
        cloudState: this.state,
        cloudSetState: this.setState.bind(this),
        createInstance: this.createInstance,
        deployService: this.deployService,
        deleteDeployService: this.deleteDeployService,
        fetchStatus: this.fetchStatus,
        updateDeployService: this.updateDeployService,
        getDeploymentEndpoint: this.getDeploymentEndpoint
      }}>
        {this.props.children}
      </CloudContext.Provider>
    )
  }

  componentWillUnmount() {
		this._isMounted = false;
		if(this._intervalID) {
			clearInterval(this._intervalID)
		}
	}
}

const withCloudHandler = (Component) => {
  return props => <CloudHandler {...props} params={useParams()}><Component/></CloudHandler>
}

export { CloudContext, withCloudHandler }
import Home from "./Home.jsx"
import About from "./About.jsx"
import Profile from "./Profile.jsx"
import Login from "./Login.jsx"
import Register from "./Register.jsx"
import Faq from "./Faq.jsx"
import Contact from "./Contact.jsx"
import CookiePolicy from "./CookiePolicy.jsx"
import TnC from "./TnC.jsx"
import HomePromt from "./HomePrompt.jsx"
import Processing from './Processing.jsx'
import SandBox from './SpeedRun/sandbox/index.jsx'
import PrivacyPolicy from "./PrivacyPolicy.jsx"
import ServiceIntegration from "./ServiceIntegration.jsx"
import LimitedUseDisclosure from "./Disclosure.jsx"

const routes = [
    { path: '/', component: <Home />},
    { path: '/nelima', component: <SandBox />},
    { path: '/service-integration', component: <ServiceIntegration/>},
    { path: '/about', component: <About />},
    { path: '/profile/:id', component: <Profile />},
    { path: '/profile/', component: <Profile />},
    { path: '/login', component: <Login />},
    { path: '/register', component: <Register />},
    { path: '/Faq', component: <Faq />},
    { path: '/contact', component: <Contact />},
    { path: '/cookie-policy', component: <CookiePolicy />},
    { path: '/terms-n-conditions', component: <TnC />},
    { path: '/privacy', component: <PrivacyPolicy />},
    { path: '/limited-use-disclosure', component: <LimitedUseDisclosure />},
    { path: '/processing', component: <Processing />},
    { path: '/:prompt', component: <HomePromt />}
  ]
  
  export default routes
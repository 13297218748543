import React , {useState} from 'react';
import '../scss/components/section.scss';
import Header from '.././components/header/header.jsx';
import Footer from '../components/footer/footer-v1';
import dataFooter from '../assets/fake-data/data-footer';
import Bottom from '../components/bottom';

function About(props) {
    const [dataTab] = useState(
        [
            {
                id: 1,
                title: "Our Philosophy",
            },
            {
                id: 2,
                title: "Our Story",
            },
            {
                id: 3,
                title: "Our Product",
            }
        ]
    )
    return (
        <div className='wrapper-style' id='wrapper'>
            {<Header />}
            <div style={{ width: '100%', height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                <div style={{textAlign: 'center'}}>
                    <span style={{fontSize: '30px', fontWeight: 'bold', color: 'rgb(170, 170, 170)'}}>About Page Under Construction</span>
                    <div style={{margin: '30px'}}></div>
                    <span style={{fontSize: '12px', fontWeight: 700, color: 'rgb(200, 200, 200)'}}>Stay tuned for updates! We're revamping this page to provide you with even more valuable content.</span>
                </div>
            </div>
            {<Footer 
                data={dataFooter}
            />}
            {<Bottom classection='widget-social-footer'/>}
        </div>
    );
}

export default About;
import React, { Component, createContext, useRef } from 'react'
import Axios from '../utilities/axios'
import { useParams } from 'react-router-dom'
import Spinner from '../components/spinner'
import SpeedRun from '../pages/SpeedRun'
import { projectComponents } from '../components/cloud-panels/project-components'
import { withCloudHandler } from './CloudContext'

const ConsoleContext = createContext()

class ConsoleBackend extends Component {
  constructor(props) {
    super(props)
    var activeComponent = 'prj-manager'
    var activeEditor = ''
    if(localStorage.hasOwnProperty(this.props.params.id)) {
			var value = localStorage.getItem(this.props.params.id)
			activeComponent = JSON.parse(value)?.activeComponent || ''
      activeEditor = JSON.parse(value)?.activeEditor || ''
		}
    this.state = {
			isMainLoading: false,
      mainError: 'An error occured loading the project',
      isLoading: false,
      project: null,
      workingOnVersion: null,
      error: null,
      showAskScriptPrompt: 'no',
      prompt: '',
      conversations: [],
      isPromptLoading: false,
      editorRef: this.props.editorRef,
      activePanels: [],
      activeComponent: activeComponent,
      activeEditor: activeEditor,
      mainUpdated: false,
      deployUpdated: false,
      mainContent: '',          // stores compute script content
      deployContent: '',         // stores deploy script content
      logMinimized: true,
      datasetInView: null,
      bs: null
		}
  }

  _isMounted = false
  _intervalID = null
  // _disableEditorUpdateTrigger = false       // defines whether Code editor is being updated manually through Context or through User, disables "registerScriptUpdate" trigger


  _internalMapping = {
    'compute': 'main',
    'deploy': 'deploy'
  }

  initialLoad = () => {
    if(!this.state.isMainLoading) {
      this.setState({isMainLoading: true})
      let projectID = String(this.props.params.id)
      if(projectID) {
        Axios.post(`/project/details`, {projectID}).then(async response => {
          if(response.status === 200 && response.data) {
            if(this._isMounted) {
              let version = null
              if(response.data?.versionDetails?.currentVersion) {
                version = response.data?.versionDetails?.currentVersion
              }
              this.setState({
                project: response.data,
                workingOnVersion: version
              }, async () => {
                this._autoSetActiveEditor(async () => {
                  let mainPy = null
                  let functionPy = null
                  try {
                    mainPy = await this._downloadCode('compute')
                    functionPy = await this._downloadCode('deploy')
                  } catch(err) {
                    console.log(err)
                  }
                  this.setState({
                    isMainLoading: false, mainError: null,
                    mainContent: mainPy || '', deployContent: functionPy || ''
                  })
                })
              })
            }
          } else {
            if(this._isMounted) {
              this.setState({project: null, isMainLoading: false, mainError: 'There was an error loading this project'})
            }
          }
        }).catch(err => {
          if(this._isMounted) {
            this.setState({project: null, isMainLoading: false, mainError: 'There was an error loading this project'})
          }
        })
      }
    }
  }

  _autoSetActiveEditor = (onStateUpdate=() => {}) => {
    if(this.state.activeEditor) {
      onStateUpdate()
    } else if(this.state.project?.components.includes('compute')) {
      this.setState({activeEditor: 'compute', onStateUpdate})
      var config = {activeComponent: 'compute'}
      config['activeEditor'] = 'compute'
      localStorage.setItem(this.props.params.id, JSON.stringify(config))
    } else if(this.state.project?.components.includes('deploy')) {
      this.setState({activeEditor: 'deploy', onStateUpdate})
      config = {activeComponent: 'deploy'}
      config['activeEditor'] = 'deploy'
      localStorage.setItem(this.props.params.id, JSON.stringify(config))
    } else if(this.state.activeComponent === 'compute' || this.state.activeComponent === 'deploy') {
      this.setState({activeEditor: this.state.activeComponent}, onStateUpdate)
    } else {
      onStateUpdate()
    }
  }

  _downloadCode = (scriptType, onSuccess=null, onError=null) => {
    var attribute = this._internalMapping[scriptType] + 'Active'
    if(this.state?.project[attribute]) {
      return Axios.get(`project/load-script/${this.props.params.id}/${scriptType}`)
      .then(response => {
        if(response.status === 200 && this._isMounted) {
          if(onSuccess) onSuccess()
          return Promise.resolve(response.data)
        } else { 
          console.log('File upload unsuccessful')
          return Promise.reject(null)
        }
      }).catch(err => {
        console.log('download code', err)
        if(onError) onError()
        return Promise.reject(null)
      })
    }
  }

  downloadOutput = (scriptType, onSuccess=null, onError=null) => {
    return Axios.get(`project/load-output/${this.props.params.id}/${scriptType}`)
    .then(response => {
      if(response.status === 200 && this._isMounted) {
        if(onSuccess) onSuccess()
        return Promise.resolve(response.data)
      } else { 
        console.log('File download unsuccessful')
        return Promise.reject(null)
      }
    }).catch(err => {
      console.log('download code error', err)
      if(onError) onError()
      return Promise.reject(null)
    })
  }

  _loadCodeOnEditor = (code) => {
    const { editorRef } = this.state
    try {
      let view = editorRef?.current?.view
      view.dispatch({
        changes: {
          from: 0, 
          to: view.state.doc.length, 
          insert: code
        }
      })
    } catch(err) {
      console.log(err)
    }
  }

  saveScript = async () => {
    if(!this.state.isLoading) {
      this.setState({isLoading: true})
      if(this.state.activeEditor === 'compute') {
        await this._saveComponentScript('compute')
      } else if(this.state.activeEditor === 'deploy') {
        await this._saveComponentScript('deploy')
      }
      this.setState({isLoading: false})
    }
  }

  _saveComponentScript = async (scriptType) => {
    const { editorRef } = this.state
    var attribute = this._internalMapping[scriptType]
    try {
      let view = editorRef?.current?.view
      const content = view.state?.doc.toString()
      var signedURL = await this._getSignedUrl(scriptType)
      this._uploadFile(signedURL, content, () => {
        if(this._isMounted) {
          var obj = {}
          obj[attribute+'Content'] = content
          obj[attribute+'Updated'] = false
          this.setState(obj)
        }
      })
    } catch(err) {
      console.log(err)
    }
  }

  _uploadFile = (url, data, onSuccess) => {
    // todo: fix upload s3 access control check: currently wildcard
    Axios.put(url, data).then(response => {
      if(response.status === 200 && this._isMounted) {
        if(onSuccess) {
          onSuccess()
        }
      } else {
        console.log('File upload unsuccessful')
      }
    }).catch(err => {
      console.log(err)
    })
  }

  _getSignedUrl = (componentID) => {
    return Axios.get(`project/save-script/${this.state.project._id}/${componentID}`)
    .then(response => {
      if(response.status === 200 && response.data) {
        const signedURL = response.data
        return Promise.resolve(signedURL)
      } else {
        return Promise.reject(null)
      }
    }).catch(err => {
      console.log(err)
      return Promise.reject(null)
    })
  }

  registerScriptUpdate = () => {
    if(this.state.activeEditor) {
      var attribute = this._internalMapping[this.state.activeEditor]+'Updated'
      if(!this.state[attribute]) {
        var obj = {}
        obj[attribute] = true
        this.setState(obj)
      }
    } else {
      if(this.state.showAskScriptPrompt === 'no') {
        this.setState({showAskScriptPrompt: 'yes'})
      }
    }
  }

  takeFunctionAction = (functionName, details=null) => {
    if(functionName === 'execute_action') {
      if(details) {
        var {action} = details
        if(action === 'run') {
          console.log("User wants to run the code")
          setTimeout(() => {
            this.setState({bs: 'Running'})
          }, 4622);
        } else if(action === 'stop') {
          console.log("User wants to stop the execution")
        } else if(action === 'deploy') {
          this.updateProject({ task: 'update-component', action: 'add', componentID: String('autopilot')})
        } else {
          console.log('Unrecognized action')
        }
      } else {
        console.log('Unspecified action details')
      }
    } else if(functionName === 'add_dataset') {
      if(details) {
        var {datasetId} = details
        // console.log(datasetId, details)
        this.updateProject({ task: 'data-import', action: 'add', datasetID: String(datasetId)})
      }
    } else if(functionName === 'attach_dataset') {
      console.log(details, 'attach gpu')
    } else if(functionName === 'show_dataset') {
      if(details) {
        var {datasetId, actionName, skipFirstNLines, preview} = details
        if(datasetId) {
          if(actionName === 'open') {
            this.setState({datasetInView: {
              datasetId,
              skipFirstNLines,
              preview
            }})
          } else {
            this.setState({datasetInView: null})
          }
        }
      }
    }
  }

  handleConversation = (onSuccess=null, onFailure=null) => {
    if(!this.state.isPromptLoading && this.state.prompt) {
      this.setState({isPromptLoading: true})
      var convs = [{role: 'user', content: this.state.prompt}, ...this.state.conversations]
      var prompts = convs.slice(0, 5)
      prompts = prompts.reverse()
      Axios.post('/instruction/chat-completion', {projectID: this.state.project._id, prompts}).then(response => {
        if(response.data) {
          if(response.data?.functionName) {
            this.takeFunctionAction(response.data?.functionName, response.data?.instructions)
            var role = 'assistant'
            var content = response.data
            if(this._isMounted) {
              this.setState({
                conversations: [{role, content}, ...convs],
                prompt: '',
                isPromptLoading: false
              }, () => {
                this.closePanel(this.state.activeComponent)
              })
              if(onSuccess) onSuccess({role, content, someAction: response.data?.functionName})
            }
          } else {
            var {role, content} = response.data?.choices[0]?.message
            if(this._isMounted) {
              this.setState({
                conversations: [{role: role, content: content}, ...convs],
                prompt: '',
                isPromptLoading: false
              }, () => {
                this.closePanel(this.state.activeComponent)
              })
              if(onSuccess) onSuccess({role, content})
            }
          }
        } else {
          console.log('error in response')
          this.setState({ isPromptLoading: false })
          if(onFailure) onFailure(null)
        }
      }).catch(err => {
        console.log(err)
        this.setState({ isPromptLoading: false })
        if(onFailure) onFailure(err)
      })
    }
  }

  updateProject = (params, onSuccess=null, onFailure=null) => {
    if(!this.state.isLoading) {
      this.setState({isLoading: true})
      let payload = {projectID: this.state.project._id, ...params}

      Axios.post('/project/update', payload).then(response => {
        if(response.data) {
          if(this._isMounted) {
            this.setState({
              project: response.data,
              isLoading: false
            })
            if(onSuccess) onSuccess(response.data)
          }
        } else {
          this.setState({ isLoading: false })
          if(onFailure) onFailure('Response error')
        }
      }).catch(err => {
        console.log(err)
        this.setState({ isLoading: false })
        if(onFailure) onFailure(err)
      })
    }
  }

  isImported = (datasetID) => {
    if(this.state.project?.datasetImports?.length > 0) {
      return this.state.project?.datasetImports.findIndex((e => e._id === datasetID)) >= 0
    } else {
      return false
    }
  }

  openPanel = (panelID) => {
    if(this.state.activePanels.includes(panelID)) return
    // if the panelID requested to open is one of the project components then replace project component panel with the requested one
    var componentPanels = Object.keys(projectComponents)
    componentPanels.push('prj-manager')

    if(componentPanels.includes(panelID)) {
      // filter out any currently active component panel(s)
      var activePanels = [...this.state.activePanels]
      var activePanelsWoComponentPanels = activePanels.filter(value => !componentPanels.includes(value))
      activePanelsWoComponentPanels.push(panelID)
      var config = {
        activePanels: activePanelsWoComponentPanels,
        activeComponent: panelID,
      }
      var editorConfig = {}
      if(panelID === 'compute' || panelID === 'deploy') {
        editorConfig['activeEditor'] = panelID
      }
      this.setState({...config, ...editorConfig}, () => {
        console.log(`active editor ${this.state.activeEditor}, active component ${this.state.activeComponent}`)
        var config = {activeComponent: panelID, ...editorConfig}
        localStorage.setItem(this.props.params.id, JSON.stringify(config))
      })
    }
  }

  saveLocally = (obj) => {
    localStorage.setItem(this.props.params.id, JSON.stringify(obj))
  }
  
  closePanel = (panelID) => {
    var activePanels = [...this.state.activePanels]
    this.setState({
      activePanels: activePanels.filter(value => value !== panelID)
    })
  }

  componentDidMount() {
    this._isMounted = true
    // this.initialLoad()
  }
  
  render() {
    if(this.state.isMainLoading) {
      return (
        <CenterMsgComponent>
          <MiniMsgComponent>
            Loading Project&nbsp;&nbsp;<Spinner style={{borderColor: '#323741', borderTopColor: 'rgb(23, 145, 86)'}}/>
          </MiniMsgComponent>
        </CenterMsgComponent>
      )
    }
    if(this.state.mainError) {
      return (
        <CenterMsgComponent>
          <MiniMsgComponent>
            {this.state.mainError}
          </MiniMsgComponent>
        </CenterMsgComponent>
      )
    }
    return(
      <ConsoleContext.Provider value={{
        state: this.state,
        setState: this.setState.bind(this),
        project: this.state.project,
        editorRef: this.state.editorRef,
        conversations: this.state.conversations,
        handleConversation: this.handleConversation,
        updateProject: this.updateProject,
        isLoading: this.state.isLoading,
        isImported: this.isImported,
        activeComponent: this.state.activeComponent,
        activePanels: this.state.activePanels,
        openPanel: this.openPanel,
        closePanel: this.closePanel,
        saveScript: this.saveScript,
        registerScriptUpdate: this.registerScriptUpdate,
        saveLocally: this.saveLocally,
        downloadOutput: this.downloadOutput,
        initialLoad: this.initialLoad
      }}>
        <SpeedRun/>
      </ConsoleContext.Provider>
    )
  }
}

const MiniMsgComponent = ({children}) => {
  return (
    <div style={{padding: '10px', backgroundColor: '#202228', borderRadius: '5px',
      fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center',
      fontSize: '14px'
    }}>
      {children}
    </div>
  )
}

// const UserAlertComponent = ({children}) => {
//   return (
//     <div style={{padding: '10px', backgroundColor: '#202228', borderRadius: '5px',
//       fontWeight: 500, display: 'flex', alignItems: 'center', justifyContent: 'center',
//       fontSize: '14px'
//     }}>
//       {children}
//     </div>
//   )
// }

const CenterMsgComponent = ({children}) => {
  return (
    <div style={{
      width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center',
      backgroundColor: '#282c34', color: 'rgb(200, 200, 200)'
    }}>
      {children}
    </div>
  )
}

const withParamsNRef = (Component) => {
  return props => <Component {...props} params={useParams()} editorRef={useRef(null)}/>
}

export { ConsoleContext }
export default withCloudHandler(withParamsNRef(ConsoleBackend))
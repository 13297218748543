const defaultComment = `# Write your Python 3 (Version 3.11) function here.
# Each function has an average execution time of 5 seconds before being interrupted.
# 
# #####################
# #### NEW UPDATE #####
# #####################
# Nelima can now interpret the image data returned by the function.
# The function should return the image data as a base64-encoded string.
# Example:
# import base64
# def function_returning_base64_encoded_string():
#   return base64.b64encode("binary_data/byte_array").decode('utf-8')
# 
# !!IMPORTANT: Be sure to check "String as Image Data" for "str" type data under the function return section.
# 
# This environment includes the following packages: BeautifulSoup, urllib3, requests, pyyaml.
# Let us know if there are any additional packages you'd like to have installed.
# 
# Join our Discord at https://discord.com/invite/zGdUve2Nhk and share your feedback with us.

`

export default defaultComment
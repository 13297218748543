const urlFormatter = (match, i) => {
  return <a key={i} className='sndbx-fm-url' href={match} target='_blank' rel='noreferrer'>
      <span className='sndbx-fm-ellipsis'>
        {match.replace(/(https?:\/\/|www\.)/g, '')}
      </span>
      <i style={{marginLeft: '5px'}} className="far fa-external-link-alt"></i>
    </a>
}

const quotationCleaner = (sentence) => {
  if(sentence) {
    sentence = sentence.trim()
    if (sentence.length >= 2 && sentence.charAt(0) === '"' && sentence.charAt(sentence.length - 1) === '"') {
      return sentence.slice(1, -1)
    } else {
      return sentence
    }
  } else {
    return sentence
  }
}

export {urlFormatter, quotationCleaner}